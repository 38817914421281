<template>
	<div class="client-list assigned-clients list-view">
		<div class="list-header">
			<div class="col">
				<p>Clients assigned</p>
			</div>
		</div>
		<div class="grouping" v-for="(value, letter) in sortedClients" v-bind:key="letter" v-bind:data-letter="letter">
			<div class="col">
				<div class="heading">
					<div class="letter" v-bind:id="letter">
						<p>{{ letter }}</p>
					</div>
				</div>
				<!-- end heading -->
			</div>
			<!-- end col -->
			<div class="col span-3">
				<div class="clients items">
					<article class="client item grid-container" v-for="client in value" v-bind:key="client.slug">
						<div class="col">
							<router-link :to="{path: `/client/${client.slug}/dashboard`}">
                            <p class="client-name item-name">{{ client.clientName }}</p></router-link>
						</div>
						<!-- end col -->
						<div class="col last remove">
							<button 
                                v-if="
                                    can(getAuthData, 'update_own_user_clients', {username: getUserUsername})
                                    ||                    
                                    can(getAuthData, 'update_other_user_clients', {}, {username: getUserUsername})
                                " 
                                class="remove" 
                                title="Remove client" 
                                v-on:click="unassignClientFromUser(client)"
                            >
								<i class="fas fa-minus-circle"></i>
							</button>
						</div>
						<!-- end col -->
					</article>
				</div>
				<!-- end clients -->
			</div>
			<!-- end col -->
		</div>
	</div>
	<!-- end client-list -->
</template>
<script>
import {mapGetters} from 'vuex';
export default {
	data() {
		return {
			sortedClients: {}, //use to order clients by letter
			searchQuery: "",
		}
	},
	mounted() {
		this.getClients()
	},
	methods: {
		async getClients() {
            // Clear the array
            this.clientsArray = [];
			try {
				this.clientsArray = [...this.$store.getters['user/getUserClients']];
				this.sortClients()
			} catch (error) {
				// console.log("oops an error", error)
			}
		},
		sortClients() {
            
            // Clear the object
            this.sortedClients = {};
			
            //get the first letter of client name
			function findFirstLetter(string) {
				return string.charAt(0)
			}

			// Sort by alphabetical order
			let tempClients = this.clientsArray
			tempClients = tempClients.sort((a, b) => {
				let fa = a.clientName.toLowerCase(),
					fb = b.clientName.toLowerCase()
				if (fa < fb) {
					return -1
				}
				if (fa > fb) {
					return 1
				}
				return 0
			})

			//sort array items under each letter
			tempClients.forEach((clientName) => {
				let clientFirstLetter = findFirstLetter(clientName.clientName)
				if (this.sortedClients[clientFirstLetter]) {
					this.sortedClients[clientFirstLetter] = [...this.sortedClients[clientFirstLetter], clientName]
				} else {
					this.sortedClients[clientFirstLetter] = [clientName]
				}
			})
		},
        async unassignClientFromUser(client){
            let confirmed = confirm(`Are you sure you want to unassign ${client.clientName} from this user?`);
            let userIds = [this.getUserId];
            if(confirmed){
                let clientSlug = client.slug;
                await this.$store.dispatch('removeClientUserRelations', {clientSlug, userIds})
                await this.$store.dispatch("user/fetchUserData", this.getUserUsername)
                this.$emit('clientRemoved');
            }
        },
		warn: function (message, event) {
			if (event) {
				event.preventDefault()
			}
			if (confirm(message)) {
				alert("confirmed")
			} else {
				alert("cancelled")
			}
		},
	},
    computed: {
        ...mapGetters('auth', ['getAuthData']),
        ...mapGetters('user', ['getUserUsername', 'getUserId'])
    }
}
</script>
