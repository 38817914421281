<template>
	<div class="user-management">
		<h1>Edit User</h1>
		<!-- GET USER FORM -->
		<AddUserForm @newUserAdded="refreshClientsList"></AddUserForm>
		<!-- GET MAIN CLIENTS LIST -->
		<ClientsAssignedList :key="clientsListKey" v-if="can(getAuthData, 'read_own_user_clients', {username: getUserUsername}) || can(getAuthData, 'read_other_user_clients', {}, {username: getUserUsername})" @clientRemoved="refreshClientsList"></ClientsAssignedList>
        <div class="user-button__wrapper" v-if="can(getAuthData, 'delete_global_user') && getAuthData.username !== getUserUsername">
            <button class="button button--log-user-out" @click="logUserOut">Log User Out</button>
            <button class="button button--delete-user" @click="deactivateUser">Delete User</button>
        </div>
	</div>
	<!-- end container -->
</template>
<script>
import AddUserForm from "../../components/forms/AddUserForm.vue"
import ClientsAssignedList from "../../components/lists/ClientsAssignedList.vue"
import {mapGetters} from "vuex"

export default {
	name: "userManagement",
	data() {
		return {
			clientsListKey: 1,
		}
	},
	components: {
		AddUserForm,
		ClientsAssignedList,
	},
	methods: {
		refreshClientsList() {
			this.clientsListKey++
		},
		// newUserAdded() {
		// 	console.log("new user added")
		// },
        // async logUserOut(){
        //     let confirmed = confirm(`Are you sure you want to log ${this.getUserFullName} out?`);
        //     // if(confirmed){
        //     //     // let logout = await this.$store.dispatch('user/logUserOut', this.getUserId);
        //     //     // console.log(logout);
        //     // }
        // },
        deactivateUser(){
            let confirmed = confirm(`Are you sure you want to deactivate ${this.getUserFullName}'s account?`);
            if(confirmed){
                this.$store.dispatch('user/deactivateUser', this.getUserId);
            }
        }
	},
	computed: {
		...mapGetters("auth", ["getAuthData"]),
		...mapGetters("user", ["getUserUsername", "getUserId", "getUserFullName"]),
	},
}
</script>
